import * as React from "react";
const SvgConnector = (props) => /* @__PURE__ */ React.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", xmlnsXlink: "http://www.w3.org/1999/xlink", width: "15px", height: "16px", viewBox: "0 0 15 16", ...props }, /* @__PURE__ */ React.createElement("g", { id: "surface1" }, /* @__PURE__ */ React.createElement("path", { style: {
  fill: "none",
  strokeWidth: 1.5625,
  strokeLinecap: "butt",
  strokeLinejoin: "miter",
  stroke: "rgb(0%,0%,0%)",
  strokeOpacity: 1,
  strokeMiterlimit: 4
}, d: "M 4.0625 3.468748 L 7.5 3.468748 C 8.104167 3.468748 8.59375 3.963133 8.59375 4.561887 L 8.59375 8.000606 C 8.59375 8.599361 8.104167 9.093745 7.5 9.093745 L 4.0625 9.093745 C 3.458333 9.093745 2.96875 8.599361 2.96875 8.000606 L 2.96875 4.561887 C 2.96875 3.963133 3.458333 3.468748 4.0625 3.468748 Z M 4.0625 3.468748 ", transform: "matrix(0.75,0,0,0.711111,0,0.533333)" }), /* @__PURE__ */ React.createElement("path", { style: {
  fill: "none",
  strokeWidth: 1.5625,
  strokeLinecap: "butt",
  strokeLinejoin: "miter",
  stroke: "rgb(0%,0%,0%)",
  strokeOpacity: 1,
  strokeMiterlimit: 4
}, d: "M 4.0625 11.906244 L 7.5 11.906244 C 8.104167 11.906244 8.59375 12.400629 8.59375 12.999383 L 8.59375 16.438102 C 8.59375 17.036857 8.104167 17.531241 7.5 17.531241 L 4.0625 17.531241 C 3.458333 17.531241 2.96875 17.036857 2.96875 16.438102 L 2.96875 12.999383 C 2.96875 12.400629 3.458333 11.906244 4.0625 11.906244 Z M 4.0625 11.906244 ", transform: "matrix(0.75,0,0,0.711111,0,0.533333)" }), /* @__PURE__ */ React.createElement("path", { style: {
  fill: "none",
  strokeWidth: 1.5625,
  strokeLinecap: "butt",
  strokeLinejoin: "miter",
  stroke: "rgb(0%,0%,0%)",
  strokeOpacity: 1,
  strokeMiterlimit: 4
}, d: "M 12.5 3.468748 L 15.9375 3.468748 C 16.541667 3.468748 17.03125 3.963133 17.03125 4.561887 L 17.03125 8.000606 C 17.03125 8.599361 16.541667 9.093745 15.9375 9.093745 L 12.5 9.093745 C 11.895833 9.093745 11.40625 8.599361 11.40625 8.000606 L 11.40625 4.561887 C 11.40625 3.963133 11.895833 3.468748 12.5 3.468748 Z M 12.5 3.468748 ", transform: "matrix(0.75,0,0,0.711111,0,0.533333)" }), /* @__PURE__ */ React.createElement("path", { style: {
  fill: "none",
  strokeWidth: 1.5625,
  strokeLinecap: "butt",
  strokeLinejoin: "miter",
  stroke: "rgb(0%,0%,0%)",
  strokeOpacity: 1,
  strokeMiterlimit: 4
}, d: "M 12.5 11.906244 L 15.9375 11.906244 C 16.541667 11.906244 17.03125 12.400629 17.03125 12.999383 L 17.03125 16.438102 C 17.03125 17.036857 16.541667 17.531241 15.9375 17.531241 L 12.5 17.531241 C 11.895833 17.531241 11.40625 17.036857 11.40625 16.438102 L 11.40625 12.999383 C 11.40625 12.400629 11.895833 11.906244 12.5 11.906244 Z M 12.5 11.906244 ", transform: "matrix(0.75,0,0,0.711111,0,0.533333)" })));
export default SvgConnector;

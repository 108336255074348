import gdoc from '@/assets/files/gdoc.svg?react';
import gsheet from '@/assets/files/gsheet.svg?react';
import gslider from '@/assets/files/gslider.svg?react';
import CustomIcon from '.';
import './GoogleIcons.less';
import React from 'react';

export enum GdriveTypes {
  doc = 'gdoc',
  sheet = 'gsheet',
  slider = 'gslider',
}
const iconMap: {
  [key: string]: {
    icon: React.FC;
    filetype: GdriveTypes;
  };
} = {
  'ppt, pptx': {
    icon: gslider,
    filetype: GdriveTypes.slider,
  },
  'doc, docx': {
    icon: gdoc,
    filetype: GdriveTypes.doc,
  },
  'xls, xlsx': {
    icon: gsheet,
    filetype: GdriveTypes.sheet,
  },
  presentation: {
    icon: gslider,
    filetype: GdriveTypes.slider,
  },
  spreadsheet: {
    icon: gsheet,
    filetype: GdriveTypes.sheet,
  },
  document: {
    icon: gdoc,
    filetype: GdriveTypes.doc,
  },
  msword: {
    icon: gdoc,
    filetype: GdriveTypes.doc,
  },
  'ms-excel': {
    icon: gsheet,
    filetype: GdriveTypes.sheet,
  },
};

const smallIconMap: {
  [key: string]: {
    icon: React.FC;
    filetype: GdriveTypes;
  };
} = {
  presentation: {
    icon: gslider,
    filetype: GdriveTypes.slider,
  },
  spreadsheet: {
    icon: gsheet,
    filetype: GdriveTypes.sheet,
  },
  document: {
    icon: gdoc,
    filetype: GdriveTypes.doc,
  },
  msword: {
    icon: gdoc,
    filetype: GdriveTypes.doc,
  },
  'ms-excel': {
    icon: gsheet,
    filetype: GdriveTypes.sheet,
  },
};

const GIcon: React.FC<{
  type?: string | undefined;
  extStr?: string; // 任意拼接字符串，只要包含 Picker 返回的 mimeType 就行，
  size?: string; // 不同组件需要的icon style 有差异
  gdriveType?: string;
}> = ({ type, extStr, size, gdriveType }) => {
  if (size === 'chat-header') {
    const hasGdriveIcon = ['doc', 'docx', 'pdf'].some((k) => type?.includes(k));
    if (hasGdriveIcon && gdriveType) {
      return <CustomIcon className="header-icon" type={gdriveType} />;
    }
    return <CustomIcon type="GdriveChatHeader" />;
  }
  if (extStr) {
    const extKey = Object.keys(smallIconMap).find((key) => extStr.includes(key));
    if (extKey) {
      const { icon: Icon, filetype } = smallIconMap[extKey];
      return (
        <span className={`google-icon-small icon-${filetype}`}>
          <Icon />
        </span>
      );
    }
    return <CustomIcon type="Gdrive" />;
  }
  const key = type && Object.keys(iconMap).find((types) => types.includes(type));
  if (!key || !iconMap[key]) {
    return <CustomIcon type="GdriveFileBig" />;
  }
  const { icon: Icon, filetype } = iconMap[key];
  return (
    <span className={`google-icon icon-${filetype}`}>
      <Icon />
    </span>
  );
};

export default GIcon;

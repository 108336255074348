/*
 * @Author: wubo
 * @Date: 2023-06-14 11:57:18
 * @LastEditTime: 2023-12-18 20:28:32
 * @LastEditors: zuoyuxing001
 * @Descripttion:
 */
import { useMemo, useState, useRef } from 'react';
import { Tooltip } from 'antd';
import Icon from '@ant-design/icons';
import classNames from 'classnames';
import { isEmpty } from 'lodash-es';
import ChatIcon from '@/assets/chat.svg?react';
import PDFIcon from '@/assets/pdf.svg?react';
import DOCIcon from '@/assets/doc.svg?react';
import CSVIcon from '@/assets/csv.svg?react';
import PPTIcon from '@/assets/ppt.svg?react';
import PPTOutlined from '@/assets/ppt-outlined.svg?react';
import PdfFileBigIcon from '@/assets/files/pdf.svg?react';
import PPTFileBigIcon from '@/assets/files/ppt.svg?react';
import DocFileBigIcon from '@/assets/files/doc.svg?react';
import CsvFileBigIcon from '@/assets/files/csv.svg?react';
import GdriveFileBigIcon from '@/assets/files/gdrive.svg?react';
import OutlineFileBigIcon from '@/assets/files/outline.svg?react';
import PdfBigIcon from '@/assets/pdf-big.svg?react';
import PPTBigIcon from '@/assets/ppt-big.svg?react';
import DocBigIcon from '@/assets/doc-big.svg?react';
import CsvBigIcon from '@/assets/csv-big.svg?react';
import WritingBigIcon from '@/assets/writing-big.svg?react';
import FolderColorizedIcon from '@/assets/folder-colorized.svg?react';
import FolderColorizedHoverIcon from '@/assets/folder-colorized-hover.svg?react';
import WorkSpaceIcon from '@/assets/workspace/menu.svg?react';
import DrawColorizedIcon from '@/assets/draw-colorized.svg?react';
import DrawColorizedHoverIcon from '@/assets/draw-colorized-hover.svg?react';
import MembershipIcon from '@/assets/membership.svg?react';
import GptTagIcon from '@/assets/gpt-tag.svg?react';
import GptTagTurboIcon from '@/assets/gpt-tag-turbo.svg?react';
import ProBadgeIcon from '@/assets/pro-badge.svg?react';
import LogoBgWhiteIcon from '@/assets/logo-bg-white.svg?react';
import LogoBgGrayIcon from '@/assets/logo-bg-gray.svg?react';
import LogoUpgradeIcon from '@/assets/logo-upgrade.svg?react';
import AlertIcon from '@/assets/alert.svg?react';
import ProBorderIcon from '@/assets/pro-border.svg?react';
import ProPlusIcon from '@/assets/proPlus.svg?react';
import ProBorderPlusIcon from '@/assets/pro-border-plus.svg?react';
import UnlimitedIcon from '@/assets/unlimited.svg?react';
import UploadImageIcon from '@/assets/upload-image.svg?react';
import UploadImageGrayIcon from '@/assets/upload-image-grayed.svg?react';
import ExtractImage from '@/assets/extract-image.svg?react';
import ExtractImageLoading from '@/assets/extract-image-loading.svg?react';
import ExtractImageUpgrade from '@/assets/extract-image-upgrade.svg?react';
import ExtractImageUpgradeAsh from '@/assets/extract-image-upgrade-ash.svg?react';
import ImageChat from '@/assets/image-chat.svg?react';
import ExtractImageIcon from '@/assets/extract-image-icon.svg?react';
import FastspringIcon from '@/assets/fastspring.svg?react';
import StripeIcon from '@/assets/stripe.svg?react';
import PaypalIcon from '@/assets/paypal.svg?react';
import CloseIcon from '@/assets/close.svg?react';
import NavImageIcon from '@/assets/navImageIcon.svg?react';
import NavImageIconHover from '@/assets/navImageIconHover.svg?react';
import Presentation from '@/assets/ai-presentation-init.svg?react';
import PresentationHover from '@/assets/ai-presentation-hover.svg?react';
import PresentationActive from '@/assets/ai-presentation-active.svg?react';
import ExclamationMark from '@/assets/exclamation-mark.svg?react';
import Eraser from '@/assets/eraser.svg?react';
import UploadPPTImg from '@/assets/upload-ppt-img.svg?react';
import Failed from '@/assets/failed.svg?react';
import Up from '@/assets/up.svg?react';
import Down from '@/assets/down.svg?react';
import Collection from '@/assets/collection.svg?react';
import History from '@/assets/history.svg?react';
import CaretUp from '@/assets/caret-up.svg?react';
import CaretDown from '@/assets/caret-down.svg?react';
import FolderOpen from '@/assets/folder-open.svg?react';
import ThemeGallery from '@/assets/theme.svg?react';
import ImportCollection from '@/assets/import-collection.svg?react';
import Shuffle from '@/assets/shuffle.svg?react';
import EraserDisabled from '@/assets/eraser-disabled.svg?react';
import ModelBasic from '@/assets/model-basic.svg?react';
import SendSvg from '@/assets/send.svg?react';
import Rocket from '@/assets/rocket.svg?react';
import FreeTrial from '@/assets/free-trial.svg?react';
import FreeTrialSuccess from '@/assets/free-trial-success.svg?react';
import YesSideBar from '@/assets/yes-siderbar.svg?react';
import Enhance from '@/assets/enhance.svg?react';
import AiMagic from '@/assets/ai-magic.svg?react';
import Atom from '@/assets/atom.svg?react';
import ImageDetail from '@/assets/image-detail.svg?react';
import Template from '@/assets/template.svg?react';
import Location from '@/assets/location.svg?react';
import Camera from '@/assets/camera.svg?react';
import Cube from '@/assets/cube.svg?react';
import Film from '@/assets/film.svg?react';
import Redo from '@/assets/redo.svg?react';
import Link from '@/assets/link.svg?react';
import AiSearch from '@/assets/ai-search.svg?react';
import AiGeneration from '@/assets/ai-generation.svg?react';
import PdfDetails from '@/assets/pdf-details.svg?react';
import DocDetails from '@/assets/doc-details.svg?react';
import PdfDetailsAr from '@/assets/pdf-details-ar.svg?react';
import DocDetailsAr from '@/assets/doc-details-ar.svg?react';
import EmptyDoc from '@/assets/empty-doc.svg?react';
import Ellipsis from '@/assets/ellipsis.svg?react';
import WebSearch from '@/assets/web-search.svg?react';
import Coupon from '@/assets/coupon.svg?react';
import UnlimitFt from '@/assets/unlimit-ft.svg?react';
import ProFt from '@/assets/pro-ft.svg?react';
import ProplusFt from '@/assets/proplus-ft.svg?react';
import PayActivity from '@/assets/payActivity.svg?react';
import Quote from '@/assets/quote.svg?react';
import PayEmail from '@/assets/pay/email.svg?react';
import FileQuote from '@/assets/files/quote.svg?react';
import Writing from '@/assets/writing.svg?react';
import Solving from '@/assets/solving.svg?react';
import Coding from '@/assets/coding.svg?react';
import General from '@/assets/gentral.svg?react';
import Hotspot from '@/assets/hotspot.svg?react';
import Publicity from '@/assets/publicity.svg?react';
import Research from '@/assets/research.svg?react';
import Tool from '@/assets/tool.svg?react';
import AddFiles from '@/assets/sendcontroller/add.svg?react';
import AddTpl from '@/assets/sendcontroller/at.svg?react';
import AddImg from '@/assets/sendcontroller/image.svg?react';
import SendChat from '@/assets/sendcontroller/send.svg?react';
import UploadDocs from '@/assets/sendcontroller/upload.svg?react';
import Url from '@/assets/sendcontroller/url.svg?react';
import AiWritingColorized from '@/assets/ai-writing-colorized.svg?react';
import AiWritingColorizedHover from '@/assets/ai-writing-colorized-hover.svg?react';
import AiWriting from '@/assets/ai-writing.svg?react';
import AiWritingSidebarTips from '@/assets/ai-writing-sidebar-tips.svg?react';
import FileLink from '@/assets/file-link.svg?react';
import Connector from '@/assets/connector.svg?react';
import Gdrive from '@/assets/workspace/ic-gdrive.svg?react';
import Obsidian from '@/assets/workspace/ic-obsidian.svg?react';
import Onedrive from '@/assets/workspace/ic-onedrive.svg?react';
import Notion from '@/assets/workspace/ic-notion.svg?react';
import Evernote from '@/assets/workspace/ic-evernote.svg?react';
import Zotero from '@/assets/workspace/ic-zotero.svg?react';
import Onenote from '@/assets/workspace/ic-onenote.svg?react';
import SuccessIcon from '@/assets/green-success.svg?react';
import GdriveChatHeader from '@/assets/chat-header-gdrive.svg?react';
import TryTheTools from '@/assets/try-the-tools.svg?react';
import HomeV2Doc from '@/assets/homeV2-doc.svg?react';
import HomeV2Ppt from '@/assets/homeV2-ppt.svg?react';
import HomeV2Writing from '@/assets/homeV2-writing.svg?react';
import RightArrowOutlinedfont from '@/assets/rightArrowOutlinedfont.svg?react';
import type { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon';
import type { TooltipPlacement } from 'antd/es/tooltip';

import {
  userSvg,
  upgradeUserSvg,
  settingSvg,
  plusFillSvg,
  plusSvg,
  uploadSvg,
  checkboxSvg,
  searchSvg,
  chatLogoSvg,
  editSvg,
  editPlusSvg,
  deleteSvg,
  checkSvg,
  closeSvg,
  fileSvg,
  loginSvg,
  logoutSvg,
  folderSvg,
  folderTowToneSvg,
  linkSvg,
  stopSvg,
  refreshSvg,
  thumbsUpSvg,
  hadThumbsUpSvg,
  thumbsDownSvg,
  hadThumbsDownSvg,
  copySvg,
  moreSvg,
  successSvg,
  errorSvg,
  gptSvg,
  productCheckSvg,
  payCloseSvg,
  leftArrowSvg,
  rightArrowSvg,
  collectSvg,
  collectedSvg,
  arrowDownSvg,
  shareSvg,
  modalCloseSvg,
  leftArrowPlusSvg,
  rightArrowPlusSvg,
  upArrowPlusSvg,
  logoTextSvg,
  googleSvg,
  keywordsSvg,
  imagesSvg,
  sourcesSvg,
  figuresSvg,
  richTextSvg,
  continueSvg,
  drawSvg,
  paperSvg,
  proSvg,
  discoverSvg,
  appleSvg,
  arrowRightSquareSvg,
  arrowBottomSquareSvg,
  userInfoSvg,
  feedbackSvg,
  referAndEarnSvg,
  mobileSvg,
  timeSvg,
  pageSvg,
  viewSvg,
  webSearchSvg,
  imgSvg,
  eyeSvg,
  previewSvg,
  translateSvg,
  // diamondSvg,
  adjustSvg,
  boardSvg,
  triangleSvg,
  tickSvg,
  giftSvg,
  giftMemberSvg,
  rightArrowOutlinedSvg,
  rightArrowCollapseSvg,
  themeSvg,
  tipSvg,
  upgradeSvg,
  jumpOutSvg,
  orderTimeSvg,
  dollarSvg,
  nextTimeSvg,
  lockTimeSvg,
  lockSvg,
  bankCardSvg,
  nextRenewalSvg,
  fullScreenSvg,
  fullScreenExitSvg,
  squareUnCheckSvg,
  squareCheckedSvg,
  checkMarkSvg,
  screenshotSvg,
  pdfSummarySvg,
  homeworkSvg,
  chromePluginFileDownSvg,
  chromeStoreSvg,
  chromeSvg,
  appStoreSvg,
  googlePlaySvg,
  androidSvg,
  iosSvg,
  newSvg,
  playSvg,
  pencilSvg,
  noParagraphSvg,
  longParagraphSvg,
  regularParagraphSvg,
  shortParagraphSvg,
  pptExportSvg,
  shareLinkSvg,
  notesSvg,
  upgradeProSvg,
  notesLogoTextSvg,
  loadingSvg,
  downArrowFilledSvg,
  finishSvg,
  uploadFileSvg,
  tipsSvg,
  zoomInSvg,
  imageDownloadSvg,
  finishImageSvg,
  insightsSvg,
  extractDataSvg,
  toolbarChatSvg,
  checkNavImageIconSvg,
  screenShotSvg,
  multiFileChatSvg,
  multiFileFolderSvg,
  checkboxOutlinedSvg,
  multiFileFolderFilledSvg,
  translateArSvg,
  accountSvg,
  uploadFilesSvg,
  uploadFileUpArrowSvg,
  homeChatDocSvg,
  newHomeUploadSvg,
  markStarSvg,
  writeSvg,
  boldSvg,
  italicSvg,
  underlineSvg,
  strikethroughSvg,
  bulletListSvg,
  numberedListSvg,
  askAISvg,
  writeUndoSvg,
  writeRedoSvg,
  fontSizeSvg,
  dragSvg,
  askAISummarySvg,
  askAIReplaceSvg,
  askAIInsertSvg,
  askAISendSvg,
  writeCloseSvg,
  writePdfSvg,
  writeWordSvg,
  writeShareChatSvg,
  warningSvg,
  heading1Svg,
  heading2Svg,
  heading3Svg,
  heading4Svg,
  askAIFixSpellSvg,
  askAIShorterSvg,
  askAILongerSvg,
  textSvg,
  writeDocSvg,
  clockSvg,
  todoListSvg,
  tableSvg,
  insertColumnBeforeSvg,
  insertColumnAfterSvg,
  insertRowBeforeSvg,
  insertRowAfterSvg,
  columnsSvg,
  dividerSvg,
  alignLeftSvg,
  alignRightSvg,
  alignCenterSvg,
  imageUploadSvg,
  writePlusSvg,
  duplicateSvg,
  copyNewSvg,
  writeDownloadSvg,
  shareActionSvg,
  writeDocListItemSvg,
  writingDeleteSvg,
  writingClearSvg,
  writingEditSvg,
  discordSvg,
  twitterSvg,
  writingSaveTipIconSvg,
  codeBlockSvg,
  calloutSvg,
  writingQuoteSvg,
  codeInlineSvg,
  userBadgeProSvg,
  userBadgeTrailSvg,
  userBadgeUnlimitedSvg,
  discountArrowSvg,
  payCardProSvg,
  payCardNaviSplitSvg,
  payCardUnlimitSvg,
  RocketIconColoredSvg,
} from './svgs';

import './index.less';

interface SvgMap {
  [key: string]: () => React.ReactElement;
}

export const svgMap: SvgMap = {
  user: userSvg,
  upgradeUser: upgradeUserSvg,
  setting: settingSvg,
  upload: uploadSvg,
  plusFill: plusFillSvg,
  plus: plusSvg,
  checkbox: checkboxSvg,
  search: searchSvg,
  chatLogo: chatLogoSvg,
  edit: editSvg,
  editPlus: editPlusSvg,
  delete: deleteSvg,
  check: checkSvg,
  close: closeSvg,
  file: fileSvg,
  login: loginSvg,
  logout: logoutSvg,
  folder: folderSvg,
  folderTowTone: folderTowToneSvg,
  link: linkSvg,
  stop: stopSvg,
  refresh: refreshSvg,
  thumbsUp: thumbsUpSvg,
  hadThumbsUp: hadThumbsUpSvg,
  thumbsDown: thumbsDownSvg,
  hadThumbsDown: hadThumbsDownSvg,
  copy: copySvg,
  more: moreSvg,
  success: successSvg,
  error: errorSvg,
  gpt: gptSvg,
  productCheck: productCheckSvg,
  payClose: payCloseSvg,
  leftArrow: leftArrowSvg,
  rightArrow: rightArrowSvg,
  collect: collectSvg,
  collected: collectedSvg,
  arrowDown: arrowDownSvg,
  share: shareSvg,
  modalClose: modalCloseSvg,
  leftArrowPlus: leftArrowPlusSvg,
  rightArrowPlus: rightArrowPlusSvg,
  upArrowPlus: upArrowPlusSvg,
  logoText: logoTextSvg,
  google: googleSvg,
  keywords: keywordsSvg,
  images: imagesSvg,
  sources: sourcesSvg,
  figures: figuresSvg,
  richText: richTextSvg,
  continue: continueSvg,
  draw: drawSvg,
  paper: paperSvg,
  pro: proSvg,
  discover: discoverSvg,
  apple: appleSvg,
  arrowRightSquare: arrowRightSquareSvg,
  arrowBottomSquare: arrowBottomSquareSvg,
  userInfo: userInfoSvg,
  feedback: feedbackSvg,
  referAndEarn: referAndEarnSvg,
  mobile: mobileSvg,
  upgrade: upgradeSvg,
  time: timeSvg,
  page: pageSvg,
  view: viewSvg,
  webSearch: webSearchSvg,
  img: imgSvg,
  eye: eyeSvg,
  preview: previewSvg,
  translate: translateSvg,
  translateAr: translateArSvg,
  account: accountSvg,
  // diamond: diamondSvg,
  adjust: adjustSvg,
  board: boardSvg,
  triangle: triangleSvg,
  tick: tickSvg,
  gift: giftSvg,
  giftMember: giftMemberSvg,
  rightArrowOutlined: rightArrowOutlinedSvg,
  rightArrowCollapse: rightArrowCollapseSvg,
  theme: themeSvg,
  tip: tipSvg,
  jumpOut: jumpOutSvg,
  orderTime: orderTimeSvg,
  dollar: dollarSvg,
  nextTime: nextTimeSvg,
  lockTime: lockTimeSvg,
  lock: lockSvg,
  bankCard: bankCardSvg,
  nextRenewal: nextRenewalSvg,
  fullScreen: fullScreenSvg,
  fullScreenExit: fullScreenExitSvg,
  squareUnCheck: squareUnCheckSvg,
  squareChecked: squareCheckedSvg,
  checkMark: checkMarkSvg,
  screenshot: screenshotSvg,
  pdfSummary: pdfSummarySvg,
  homework: homeworkSvg,
  chromePluginFileDown: chromePluginFileDownSvg,
  chromeStore: chromeStoreSvg,
  chrome: chromeSvg,
  appStore: appStoreSvg,
  googlePlay: googlePlaySvg,
  discord: discordSvg,
  twitter: twitterSvg,
  android: androidSvg,
  ios: iosSvg,
  new: newSvg,
  play: playSvg,
  pencil: pencilSvg,
  noParagraph: noParagraphSvg,
  longParagraph: longParagraphSvg,
  regularParagraph: regularParagraphSvg,
  shortParagraph: shortParagraphSvg,
  pptExport: pptExportSvg,
  shareLink: shareLinkSvg,
  notesLogoText: notesLogoTextSvg,
  notes: notesSvg,
  upgradePro: upgradeProSvg,
  loading: loadingSvg,
  downArrowFilled: downArrowFilledSvg,
  finish: finishSvg,
  uploadFile: uploadFileSvg,
  tips: tipsSvg,
  zoomIn: zoomInSvg,
  imageDownload: imageDownloadSvg,
  finishImage: finishImageSvg,
  insights: insightsSvg,
  extractData: extractDataSvg,
  toolbarChat: toolbarChatSvg,
  navImgae: checkNavImageIconSvg,
  screenShot: screenShotSvg,
  multiFileChat: multiFileChatSvg,
  multiFileFolder: multiFileFolderSvg,
  checkboxOutlined: checkboxOutlinedSvg,
  multiFileFolderFilled: multiFileFolderFilledSvg,
  uploadFiles: uploadFilesSvg,
  uploadFileUpArrow: uploadFileUpArrowSvg,
  homeChatDoc: homeChatDocSvg,
  newHomeUpload: newHomeUploadSvg,
  markStarSvg: markStarSvg,
  write: writeSvg,
  bold: boldSvg,
  italic: italicSvg,
  underline: underlineSvg,
  strikethrough: strikethroughSvg,
  bulletList: bulletListSvg,
  numberedList: numberedListSvg,
  askAI: askAISvg,
  writeUndo: writeUndoSvg,
  writeRedo: writeRedoSvg,
  fontSize: fontSizeSvg,
  drag: dragSvg,
  askAISummary: askAISummarySvg,
  askAIReplace: askAIReplaceSvg,
  askAIInsert: askAIInsertSvg,
  askAISend: askAISendSvg,
  writeClose: writeCloseSvg,
  writePdf: writePdfSvg,
  writeWord: writeWordSvg,
  writeShareChat: writeShareChatSvg,
  warning: warningSvg,
  heading1: heading1Svg,
  heading2: heading2Svg,
  heading3: heading3Svg,
  heading4: heading4Svg,
  askAIFixSpell: askAIFixSpellSvg,
  askAIShorter: askAIShorterSvg,
  askAILonger: askAILongerSvg,
  text: textSvg,
  writeDoc: writeDocSvg,
  clock: clockSvg,
  todoList: todoListSvg,
  table: tableSvg,
  insertColumnBefore: insertColumnBeforeSvg,
  insertColumnAfter: insertColumnAfterSvg,
  insertRowBefore: insertRowBeforeSvg,
  insertRowAfter: insertRowAfterSvg,
  columns: columnsSvg,
  divider: dividerSvg,
  alignLeft: alignLeftSvg,
  alignCenter: alignCenterSvg,
  alignRight: alignRightSvg,
  imageUpload: imageUploadSvg,
  writePlus: writePlusSvg,
  duplicate: duplicateSvg,
  copyNew: copyNewSvg,
  writeDownload: writeDownloadSvg,
  shareAction: shareActionSvg,
  writeDocListItem: writeDocListItemSvg,
  writingDelete: writingDeleteSvg,
  writingClear: writingClearSvg,
  writingEdit: writingEditSvg,
  writingSaveTipIcon: writingSaveTipIconSvg,
  codeBlock: codeBlockSvg,
  codeInline: codeInlineSvg,
  callout: calloutSvg,
  writingQuote: writingQuoteSvg,
  userBadgePro: userBadgeProSvg,
  userBadgeTrail: userBadgeTrailSvg,
  userBadgeUnlimited: userBadgeUnlimitedSvg,
  discountArrow: discountArrowSvg,
  payCardPro: payCardProSvg,
  payCardUnlimit: payCardUnlimitSvg,
  payCardNaviSplit: payCardNaviSplitSvg,
  RocketIconColored: RocketIconColoredSvg,
};

export const svgFileMap: SvgMap = {
  chat: () => <ChatIcon />,
  image: () => <ChatIcon />, // 和chat一样
  pdf: () => <PDFIcon />,
  doc: () => <DOCIcon />,
  docx: () => <DOCIcon />,
  csv: () => <CSVIcon />,
  ppt: () => <PPTIcon />,
  pptOutlined: () => <PPTOutlined />,
  pdfBig: () => <PdfBigIcon />,
  pptBig: () => <PPTBigIcon />,
  docBig: () => <DocBigIcon />,
  docxBig: () => <DocBigIcon />,
  csvBig: () => <CsvBigIcon />,
  writingFileBig: () => <WritingBigIcon />,
  outlineFileBig: () => <OutlineFileBigIcon />,
  pdfFileBig: () => <PdfFileBigIcon />,
  pptFileBig: () => <PPTFileBigIcon />,
  pptxFileBig: () => <PPTFileBigIcon />,
  docFileBig: () => <DocFileBigIcon />,
  docxFileBig: () => <DocFileBigIcon />,
  csvFileBig: () => <CsvFileBigIcon />,
  xlsFileBig: () => <CsvFileBigIcon />,
  xlsxFileBig: () => <CsvFileBigIcon />,
  GdriveFileBig: () => <GdriveFileBigIcon />,
  folderColorized: () => <FolderColorizedIcon />,
  folderColorizedHover: () => <FolderColorizedHoverIcon />,
  workSpaceIcon: () => <WorkSpaceIcon />,
  drawColorized: () => <DrawColorizedIcon />,
  drawColorizedHover: () => <DrawColorizedHoverIcon />,
  membership: () => <MembershipIcon />,
  gptTag: () => <GptTagIcon />,
  gptTagTurbo: () => <GptTagTurboIcon />,
  proBadge: () => <ProBadgeIcon />,
  logoBgWhite: () => <LogoBgWhiteIcon />,
  logoBgGray: () => <LogoBgGrayIcon />,
  logoUpgrade: () => <LogoUpgradeIcon />,
  alert: () => <AlertIcon />,
  payProBorder: () => <ProBorderIcon />,
  proPlus: () => <ProPlusIcon />,
  payProPlusBorder: () => <ProBorderPlusIcon />,
  unlimited: () => <UnlimitedIcon />,
  uploadImage: () => <UploadImageIcon />,
  uploadImageGrayed: () => <UploadImageGrayIcon />,
  extractImage: () => <ExtractImage />,
  extractImageLoading: () => <ExtractImageLoading />,
  imageChat: () => <ImageChat />,
  extractImageIcon: () => <ExtractImageIcon />,
  extractImageUpgrade: () => <ExtractImageUpgrade />,
  extractImageUpgradeAsh: () => <ExtractImageUpgradeAsh />,
  fastspring: () => <FastspringIcon />,
  stripe: () => <StripeIcon />,
  paypal: () => <PaypalIcon />,
  closeIcon: () => <CloseIcon />,
  navImageIcon: () => <NavImageIcon />,
  navImageIconHover: () => <NavImageIconHover />,
  presentation: () => <Presentation />,
  presentationHover: () => <PresentationHover />,
  presentationActive: () => <PresentationActive />,
  ExclamationMark: () => <ExclamationMark />,
  eraser: () => <Eraser />,
  uploadPPTImg: () => <UploadPPTImg />,
  failed: () => <Failed />,
  collection: () => <Collection />,
  up: () => <Up />,
  down: () => <Down />,
  history: () => <History />,
  caretUp: () => <CaretUp />,
  caretDown: () => <CaretDown />,
  folderOpen: () => <FolderOpen />,
  themeGallery: () => <ThemeGallery />,
  importCollection: () => <ImportCollection />,
  shuffle: () => <Shuffle />,
  eraserDisabled: () => <EraserDisabled />,
  modelBasic: () => <ModelBasic />,
  send: () => <SendSvg />,
  rocket: () => <Rocket />,
  freetrial: () => <FreeTrial />,
  freeTrialSuccess: () => <FreeTrialSuccess />,
  yesSideBar: () => <YesSideBar />,
  enhance: () => <Enhance />,
  aiMagic: () => <AiMagic />,
  atom: () => <Atom />,
  imageDetail: () => <ImageDetail />,
  cube: () => <Cube />,
  location: () => <Location />,
  camera: () => <Camera />,
  film: () => <Film />,
  template: () => <Template />,
  redo: () => <Redo />,
  enhanceLink: () => <Link />,
  aiSearch: () => <AiSearch />,
  aiGeneration: () => <AiGeneration />,
  pdfDetails: () => <PdfDetails />,
  pdfDetailsAr: () => <PdfDetailsAr />,
  docDetails: () => <DocDetails />,
  docDetailsAr: () => <DocDetailsAr />,
  emptyDoc: () => <EmptyDoc />,
  ellipsis: () => <Ellipsis />,
  webSearchIcon: () => <WebSearch />,
  coupon: () => <Coupon />,
  unlimitFt: () => <UnlimitFt />,
  proFt: () => <ProFt />,
  proplusFt: () => <ProplusFt />,
  payActivity: () => <PayActivity />,
  quote: () => <Quote />,
  payEmail: () => <PayEmail />,
  aiWritingColorized: () => <AiWritingColorized />,
  aiWritingColorizedHover: () => <AiWritingColorizedHover />,
  aiWritingSidebarTips: () => <AiWritingSidebarTips />,
  aiWriting: () => <AiWriting />,
  fileQuote: () => <FileQuote />,
  FileLink: () => <FileLink />,
  connector: () => <Connector />,
  Gdrive: () => <Gdrive />,
  Obsidian: () => <Obsidian />,
  Onedrive: () => <Onedrive />,
  Evernote: () => <Evernote />,
  Notion: () => <Notion />,
  Zotero: () => <Zotero />,
  Onenote: () => <Onenote />,
  SuccessIcon: () => <SuccessIcon />,
  writing: () => <Writing />,
  solving: () => <Solving />,
  coding: () => <Coding />,
  general: () => <General />,
  hotspot: () => <Hotspot />,
  publicity: () => <Publicity />,
  research: () => <Research />,
  tool: () => <Tool />,
  GdriveChatHeader: () => <GdriveChatHeader />,
  TryTheTools: () => <TryTheTools />,
  HomeV2Doc: () => <HomeV2Doc />,
  HomeV2Ppt: () => <HomeV2Ppt />,
  HomeV2Writing: () => <HomeV2Writing />,
  RightArrowOutlinedfont: () => <RightArrowOutlinedfont />,
  addFiles: () => <AddFiles />, // 输入框增加文件
  addTpl: () => <AddTpl />, // 输入框增加模版
  addImg: () => <AddImg />, // 输入框增加图片
  sendChat: () => <SendChat />, // 输入框发送按钮
  uploadDocs: () => <UploadDocs />, // 输入框内上传文件
  url: () => <Url />, // 输入框内url
};

interface CustomIconProps extends Partial<CustomIconComponentProps> {
  type: string;
  tooltip?: string | React.ReactElement;
  text?: string | React.ReactElement;
  gap?: number;
  placement?: TooltipPlacement;
  zIndex?: number;
  className?: string;
  autoAdjustOverflow?: boolean;
  tooltipOverlayInnerStyle?: React.CSSProperties;
  tooltipMouseLeaveDelay?: number;
  overlayClassName?: string;
  onClick?: (event: React.MouseEvent) => void;
  onMouseDown?: (event: React.MouseEvent) => void;
}

const CustomIcon: React.FC<CustomIconProps> = (props) => {
  const {
    type,
    tooltip = '',
    text,
    className = '',
    gap = 4,
    placement = 'top',
    zIndex = 0,
    autoAdjustOverflow = false,
    tooltipOverlayInnerStyle,
    tooltipMouseLeaveDelay = 0,
    onClick,
    onMouseDown,
    overlayClassName,
    ...otherProps
  } = props;

  const [tooltipOpen, setTooltipOpen] = useState(false);
  const tooltipRef = useRef<HTMLDivElement>(null);

  const isSvgFileMap = useMemo(() => {
    return Object.keys(svgFileMap).includes(type);
  }, [type]);

  const isSvgMap = useMemo(() => {
    return Object.keys(svgMap).includes(type);
  }, [type]);

  const iconRender = (hasText: boolean) => {
    const events = !hasText
      ? {
          onClick,
          onMouseDown,
        }
      : {};

    if (isSvgFileMap) {
      return (
        <span
          className={classNames({
            'svg-file-icon': true,
            [className]: !hasText,
          })}
          {...events}
        >
          {svgFileMap[type]?.()}
        </span>
      );
    } else if (isSvgMap) {
      return (
        <Icon
          className={classNames({
            [className]: !hasText,
          })}
          component={svgMap[type]}
          {...events}
          {...otherProps}
        />
      );
    } else {
      return (
        <span
          className={classNames(`iconfont ${type}`, {
            [className]: !hasText,
          })}
          {...events}
          {...otherProps}
        ></span>
      );
      // return <span className={`iconfont ${type}`}></span>;
    }
  };

  const contentRender = () => {
    return text ? (
      <div
        className={classNames({
          'custom-icon-content': true,
          [className]: true,
        })}
        style={{ gap }}
        onClick={onClick}
        onMouseDown={onMouseDown}
      >
        {type && iconRender(true)}
        <span className="icon-text">{text}</span>
      </div>
    ) : (
      iconRender(false)
    );
  };

  return tooltip ? (
    <div
      className="custom-icon-container"
      ref={tooltipRef}
      onMouseEnter={() => setTooltipOpen(true)}
      onMouseLeave={() => setTooltipOpen(false)}
      onMouseDown={() => setTooltipOpen(false)}
    >
      <Tooltip
        overlayClassName={isEmpty(overlayClassName) ? 'custom-icon-tooltip' : overlayClassName}
        placement={placement}
        autoAdjustOverflow={autoAdjustOverflow}
        destroyTooltipOnHide
        open={tooltipOpen}
        title={tooltip}
        zIndex={zIndex}
        overlayInnerStyle={tooltipOverlayInnerStyle}
        mouseLeaveDelay={tooltipMouseLeaveDelay}
        getPopupContainer={() => tooltipRef?.current as HTMLDivElement}
      >
        {contentRender()}
      </Tooltip>
    </div>
  ) : (
    <div className="custom-icon-container">{contentRender()}</div>
  );
};

export default CustomIcon;
